import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu']

  

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'
    
    this.handleEscape = (e) => {
      if (e.key === 'Esc' || e.key === 'Escape') {
        if (
          !this.menuTarget.classList.contains(this.toggleClass)
        ) {
          this.menuTarget.classList.add(this.toggleClass)
        }
      }
    }

    document.addEventListener('keydown', this.handleEscape);
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleEscape);
  }

  toggle() {
    this.menuTarget.classList.toggle(this.toggleClass)
  }

  hide(event) {
    if (
      this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains(this.toggleClass)
    ) {
      this.menuTarget.classList.add(this.toggleClass)
    }
  }
}